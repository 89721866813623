import React from "react"
import styled from "styled-components"
import Seo from "src/components/SEO"
import SectionFullBleed from "src/components/Section/SectionFullBleed"

const ThankYouPage = () => {
  return (
    <Section>
      <Seo title="Thank you!" />
      <SectionFullBleed
        primaryTitle="Thank you!"
        title="Thank you for your support! Your help makes us even more effective in our fight for animals."
        image="/images/hero/hen.jpg"
      />
    </Section>
  )
}

const Section = styled.div``

export default ThankYouPage
