import styled from "styled-components"
import { media } from "src/styles/breakpoints"

const SectionTag = styled.h2`
  display: inline-block;
  height: 26px;
  margin-bottom: 22px;
  padding: 0 18px;
  border: 1px solid #c6e1e1;
  border-radius: 13px;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: #076262;
`

const PrimaryTitle = styled.h2`
  margin-bottom: 45px;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.43;
  letter-spacing: -0.01em;
  color: #000;

  small {
    font-weight: 200;
    /* color: ${(p) => p.theme.color.textLight}; */
  }

  ${media.tablet} {
    font-size: 31px;
  }

  ${media.desktop} {
    font-size: 35px;
  }
`

const SecondaryTitle = styled.h3`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #000;

  h2 + & {
    margin-top: 110px;
  }

  p + & {
    margin-top: 70px;
  }

  ${media.tablet} {
    font-size: 22px;
  }

  ${media.desktop} {
    font-size: 25px;
  }
`

const TertiaryTitle = styled.h4`
  margin-bottom: 26px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
`

const Statement = styled.h2`
  /* max-width: 30em; */
  font-weight: 500;
  font-size: 19px;
  color: #000;

  small {
    /* opacity: 0; */
    /* color: ${(p) => p.theme.color.textLight}; */
    color: #74747B;
  }

  ${media.tablet} {
    font-size: 22px;
  }

  ${media.desktop} {
    font-size: 28px;
  }
`

export { SectionTag, PrimaryTitle, SecondaryTitle, TertiaryTitle, Statement }
