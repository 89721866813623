import { useEffect, useRef } from "react"

export default function useIntersectionObserver({
  callback,
  elementRef,
  options,
}) {
  const observer = useRef()

  function addIntersectionObserver(el) {
    const observer = new IntersectionObserver((changes) => {
      changes.forEach((change) => {
        callback(change)
      })
    }, options || {})

    observer.observe(el)
    return observer
  }

  useEffect(() => {
    // console.log(elementRef)
    if (!elementRef.current) return

    if (!observer.current) {
      observer.current = addIntersectionObserver(elementRef.current)
    } else {
      observer.current.unobserve(elementRef.current)
      observer.current = addIntersectionObserver(elementRef.current)
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    return () => {
      if (!elementRef.current || !observer.current) return
      observer.current.unobserve(elementRef.current)
    }
  }, [elementRef.current])
}
