import { /*useState,*/ useEffect } from "react"

export default function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  // const [windowSize, setWindowSize] = useState(getSize)
  const windowSize = getSize()

  useEffect(() => {
    if (!isClient) {
      return false
    }

    // function handleResize() {
    //   setWindowSize(getSize())
    // }

    // disable resizing detection for now
    // window.addEventListener("resize", handleResize)
    // return () => window.removeEventListener("resize", handleResize)
  }, [isClient]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
