import React, { useState, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"
import useWindowSize from "src/hooks/useWindowSize"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import { SectionTag, Statement } from "src/components/Typography"

const SectionFullBleed = ({ tag, primaryTitle, title, image, imageSharp }) => {
  const [observed, setObserved] = useState(false)
  const container = useRef()

  useIntersectionObserver({
    callback: (change) => {
      if (change.isIntersecting) {
        setObserved(true)
      }
    },
    elementRef: container,
    options: {
      rootMargin: "-100px",
    },
  })

  return (
    <Wrapper windowHeight={useWindowSize().height}>
      {imageSharp ? (
        <BackgroundFluid image={imageSharp.gatsbyImageData} alt={title} />
      ) : (
        <Background src={image} loading="lazy" alt={title}/>
      )}
      <Container ref={container} className={observed ? "-observed" : ""}>
        {tag && <Tag>{tag}</Tag>}
        {primaryTitle && <PrimaryTitle>{primaryTitle}</PrimaryTitle>}
        <Title>{title}</Title>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  max-height: ${(p) => p.windowHeight}px;
  overflow: hidden;
  margin-bottom: 60px;
  position: relative;
  color: #fff;

  &:not(:first-child) {
    margin-top: 110px;
    margin-bottom: 120px;
  }

  &:after {
    content: "";
    background: #000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${media.tablet} {
    max-height: 70vw;
    margin-bottom: 110px;

    &:not(:first-child) {
      margin-bottom: 150px;
    }
  }

  ${media.desktop} {
    margin-bottom: 195px;

    &:not(:first-child) {
      margin-top: 220px;
      margin-bottom: 195px;
    }
  }
`

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;
`

const Background = styled.img`
  ${backgroundStyles}
`

const BackgroundFluid = styled(GatsbyImage)`
  ${backgroundStyles}
  position: absolute !important;
`

const Container = styled(BaseContainer)`
  padding-bottom: 80px;
  position: relative;
  z-index: 1;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  > :nth-child(3) {
    transition-delay: 0.3s;
  }

  &:not(.-observed) {
    > * {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  ${media.desktopMedium} {
    padding-bottom: 135px;
  }
`

const Tag = styled(SectionTag)`
  border-color: rgba(255, 255, 255, 0.4);
  color: inherit;
`

const PrimaryTitle = styled.h1`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 53px;
  line-height: 1.32;
  letter-spacing: -0.02em;

  strong {
    font-weight: 500;
  }
`

const Title = styled(Statement)`
  max-width: 25em;
  color: inherit;

  ${media.desktopMedium} {
    max-width: 32em;
  }
`

export default SectionFullBleed
